import React, { FormEvent, useEffect, useState } from 'react'
import Account from '@/components/template/Account'
import { Auth } from '@aws-amplify/auth'
import AuthFlow from '@/components/atoms/AuthFlow'
import { Link, navigate } from 'gatsby'
import theme from '@/theme'
import translate from '@/utils/translations'
import { authApi } from '@/ghgApi'
import { checkAccessUser, signOut } from '@/services/auth'
import cache from '@/utils/cache'
import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import ErrorIcon from '@/components/atoms/Icon/svg/ErrorIcon'
import { makeStyles } from '@material-ui/core'
import { withFooter } from '@/components/molecules/Footer'
import { ROUTES } from '@/routes'
import OutlineButton from '@/components/atoms/Button/OutlineButton'
import { passwordValidate } from '@/utils/validate'

const useStyle = makeStyles({
    login: {
        minHeight: `calc(100vh - 50px - ${theme.footer.footerHeight})`,
    },
    loginForm: { minWidth: 450, width: '100%' },
    formFields: { display: 'flex', flexDirection: 'column', rowGap: '25px' },
    forgotPasswordLink: {
        display: 'block',
        color: theme.colors.primaryN,
        textDecoration: 'underline',
        fontSize: 14,
        marginTop: 10,
    },
    errorText: {
        listStyle: 'none',
        fontWeight: 600,
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
    },
    outlineBtn: {
        marginTop: 5,
    },
    listInstruction: {
        margin: 0,
        paddingLeft: 20,
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    instructionText: {
        color: theme.colors.black,
        fontWeight: 'lighter',
        margin: 0,
        fontSize: 14,
    },
})

function LoginInner() {
    const [errors, setErrors] = useState([] as Array<string>)
    const [showForm, setShowForm] = useState(false)
    const [user, setUser] = useState<any>()
    const [loading, setLoading] = useState(false)
    const classes = useStyle()

    useEffect(() => {
        const checkAccess = async () => {
            const isAccess = await checkAccessUser()
            if (isAccess) navigate('/')
            else setShowForm(true)
        }
        checkAccess()
    }, [])

    const changePasswordSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (errors.length) setErrors([])

        const form = e.currentTarget
        const elements = form.elements as typeof form.elements & {
            password: HTMLInputElement
            password_confirm: HTMLInputElement
        }
        if (user) {
            const errors = passwordValidate(user.challengeParam.userAttributes.email, elements.password.value)
            if (errors.length) {
                setErrors(errors)
                return
            }
            setLoading(true)
            try {
                await await Auth.completeNewPassword(user, elements.password.value)
                const userInfo = await authApi.login({
                    email: user.challengeParam.userAttributes.email,
                    password: elements.password.value,
                })
                cache.set('access_token', userInfo.data.access_token)
                window.location.reload()
            } catch (err) {
                setLoading(false)
                console.log(err)
            }
        }
    }

    const signIn = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (errors.length) setErrors([])

        const form = e.currentTarget
        const elements = form.elements as typeof form.elements & {
            username: HTMLInputElement
            password: HTMLInputElement
        }
        setLoading(true)
        try {
            const user = await Auth.signIn(elements.username.value, elements.password.value)
            if (user) {
                // https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#complete-new-password
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    setUser(user)
                    setLoading(false)
                    return
                }
                const userInfo = await authApi.login({
                    email: elements.username.value,
                    password: elements.password.value,
                })
                cache.set('access_token', userInfo.data.access_token)
                window.location.reload()
            }
        } catch (err: any) {
            if (err) {
                setLoading(false)
                const translation = translate(err.response?.data?.errors[0]?.message || err?.message)
                if (translation) setErrors([translation])
                if (err.response) {
                    signOut()
                }
            }
        }
    }

    const LoginForm = () => (
        <form onSubmit={signIn} className={classes.loginForm}>
            <div className={classes.formFields}>
                <AuthFlow.FormField
                    id="login-form-username"
                    name="username"
                    type="email"
                    placeholder="メールアドレス"
                />

                <AuthFlow.PasswordField id="login-form-password" name="password" placeholder="パスワード" />
            </div>

            <Link className={classes.forgotPasswordLink} to={ROUTES.FORGOT_PASSWORD}>
                パスワードをお忘れの方はこちら
            </Link>

            <div>
                <AuthFlow.ErrorsList>
                    {Boolean(errors.length) &&
                        errors.map((error, index) => (
                            <li key={index} className={classes.errorText}>
                                <ErrorIcon />
                                &nbsp;
                                {error}
                            </li>
                        ))}
                </AuthFlow.ErrorsList>
            </div>
            <PrimaryButton disabled={loading} fullSize type="submit">
                ログイン
            </PrimaryButton>
        </form>
    )
    const ForceChangePassword = () => (
        <form onSubmit={changePasswordSubmit} className={classes.loginForm}>
            <AuthFlow.FlexCenter>
                <div>
                    <h4 className={classes.instructionText}>初回ログイン時にパスワードの変更をお願いしております。</h4>
                    <br />
                    <h4 className={classes.instructionText}>以下の条件を満たすパスワードを設定ください。</h4>
                    <ul className={classes.listInstruction}>
                        <li className={classes.instructionText}>
                            半角の英大文字、英小文字、数字をそれぞれ1文字以上含む
                        </li>
                        <li className={classes.instructionText}>12文字以上</li>
                        <li className={classes.instructionText}>メールアドレスと異なる</li>
                    </ul>
                </div>
            </AuthFlow.FlexCenter>
            <div style={{ marginTop: 20 }}>
                <AuthFlow.PasswordField id="password" name="password" placeholder="新しいパスワード" />
            </div>
            <div>
                <AuthFlow.ErrorsList>
                    {Boolean(errors.length) &&
                        errors.map((error, index) => (
                            <li key={index} className={classes.errorText}>
                                <ErrorIcon />
                                &nbsp;
                                {error}
                            </li>
                        ))}
                </AuthFlow.ErrorsList>
            </div>
            <PrimaryButton disabled={loading} fullSize type="submit" style={{ marginTop: 15 }}>
                変更
            </PrimaryButton>
            <OutlineButton
                fullSize
                className={classes.outlineBtn}
                onClick={() => {
                    setErrors([])
                    setUser(undefined)
                }}
            >
                戻る
            </OutlineButton>
        </form>
    )

    if (!showForm) return null

    return (
        <div className={classes.login}>
            <Account subtitile={user ? 'ログインパスワード変更' : 'ログイン'}>
                <title>ログイン｜Emission View</title>
                {user ? <ForceChangePassword /> : <LoginForm />}
            </Account>
        </div>
    )
}
const Login = () => <LoginInner />
export default withFooter(Login)
